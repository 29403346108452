/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

import {isLoggedIn, logout} from "../services/auth";
import {emailContato, telefoneContato, urlSite} from "../services/shopinfo.service";
import MenuCarrinhoComponent from "./MenuCarrinho.component";


let aceiteCooke = null;
if(typeof window !== "undefined")
 aceiteCooke = window.localStorage.getItem('aceiteCookie');


function checkAceiteCookie(){
    let result = false;
    if(typeof window !== "undefined"){
        aceiteCooke = window.localStorage.getItem('aceiteCookie')
        if(aceiteCooke){
            result = !!aceiteCooke
        }

    }
        return result

}


function criarCookieAceite(){
    window.localStorage.setItem('aceiteCookie','true')
    if(window.alertaCookieDiv){
        window.alertaCookieDiv.classList.add('hide')
    }
}

const Layout = ({ children }) => {

  return (
    <>
      <header >
          <div className="header-top-bar theme-bg">
              <div className="container xs-full">
                  <div className="row">
                      <div className="col-sm-8">
                          <ul className="call-to-action list-inline">
                              <li><span> <i className="fa fa-envelope"/> Email: </span> {emailContato}</li>
                              <li><span> <i className="fa fa-whatsapp"/> Whatsapp: </span> {telefoneContato}</li>
                          </ul>
                      </div>
                      <div className="col-sm-4">
                          <div className="social-icon text-right">
                              {/*<a href="#"><i className="ion-social-facebook"></i></a>*/}
                              {/*<a href="#"><i className="ion-social-twitter"></i></a>*/}
                              {/*<a href="#"><i className="ion-social-googleplus"></i></a>*/}
                              {/*<a href="#"><i className="ion-social-linkedin"></i></a>*/}
                              {/*<a href="#"><i className="ion-social-youtube"></i></a>*/}

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="menu-area ">
              <div className="container md-full xs-full">
                  <div className="row">
                      <div className="col-md-3">
                          <div className="logo">
                              <Link to="/"><img className="logo" src="/img/logo-orthonet.png" alt=""/></Link>
                          </div>
                      </div>
                      <div className="col-md-9">
                          <div className="main-menu text-right hidden-xs hidden-sm">
                              <nav>
                                  <ul className="basic-menu">
                                      <li>
                                          <Link to="/">Início</Link>
                                      </li>
                                      {isLoggedIn() ? (
                                          <>
                                              <MenuCarrinhoComponent />
                                              <li>
                                                  <Link to="/app/minha-conta"> <i className="fa fa-user-o"/> Minha conta</Link>
                                                  <ul>
                                                      <li><Link to="/app/minha-conta">Meus dados</Link></li>
                                                      <li><Link to="/app/meus-pedidos">Meus pedidos</Link></li>

                                                      <li><a className="clickable"
                                                             onClick={event => {
                                                                 event.preventDefault()
                                                                 logout(() => navigate(`/`))
                                                             }}>Sair</a></li>
                                                  </ul>
                                              </li>
                                          </>
                                      ) : (
                                          <>
                                              <li>
                                                  <Link to="/carrinho">
                                                      Carrinho
                                                  </Link>
                                              </li>
                                            <li>
                                                <Link to="/login">Entrar ou criar conta</Link>
                                            </li>
                                          </>
                                      )}
                                  </ul>
                              </nav>
                              {/*<nav>
                                  <ul className="basic-menu">
                                      <li><a href="/">home</a>
                                          <ul>
                                              <li><a href="index.html">Home style 1</a></li>
                                              <li><a href="index-2.html">Home style 2</a></li>
                                              <li><a href="index-3.html">Home style 3</a></li>
                                              <li><a href="index-4.html">Home style 4</a></li>
                                              <li><a href="index-5.html">Home style 5</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="about.html">about us</a></li>
                                      <li><a href="doctor.html">Doctors</a>
                                          <ul>
                                              <li><a href="doctor.html">doctor style 1</a></li>
                                              <li><a href="doctor-2.html">doctor style 2</a></li>
                                              <li><a href="doctor-single.html">doctor Details</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="#">pages</a>
                                          <ul>
                                              <li><a href="service.html">service</a></li>
                                              <li><a href="service-details.html">service-details</a></li>
                                              <li><a href="departments-style-1.html">departments style 1</a></li>
                                              <li><a href="departments-style-2.html">departments style 2</a></li>
                                              <li><a href="department-single.html">department single</a></li>
                                              <li><a href="appointment-advanced.html">appointment</a></li>
                                              <li><a href="book-appointment-form.html">appointment form</a></li>
                                              <li><a href="gallery-2.html">gallery 3 column</a></li>
                                              <li><a href="gallery.html">gallery 2 column</a></li>
                                              <li><a href="faq.html">faq</a></li>
                                              <li><a href="features.html">features</a></li>
                                              <li><a href="policies.html">policies</a></li>
                                              <li><a href="pricing.html">pricing</a></li>
                                              <li><a href="process.html">process</a></li>
                                              <li><a href="testimonial.html">testimonial</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="product.html">shop</a>
                                          <ul>
                                              <li><a href="product.html">product</a></li>
                                              <li><a href="product-left-sidebar.html">product left sidebar</a></li>
                                              <li><a href="product-right-sidebar.html">product right sidebar</a></li>
                                              <li><a href="product-details.html">product-details</a></li>
                                              <li><a href="shop-cart.html">shop cart</a></li>
                                              <li><a href="shop-checkout.html">shop checkout</a></li>
                                              <li><a href="login.html">login</a></li>
                                              <li><a href="register.html">register</a></li>
                                              <li><a href="password-recovery.html">password recovery</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="blog-left-sidebar.html">blog</a>
                                          <ul>
                                              <li><a href="blog-2-col.html">blog 2 col</a></li>
                                              <li><a href="blog-3-col.html">blog 3 col</a></li>
                                              <li><a href="blog-no-sidebar.html">blog no sidebar</a></li>
                                              <li><a href="blog-right-sidebar.html">blog right sidebar</a></li>
                                              <li><a href="blog-details.html">blog Details 1</a></li>
                                              <li><a href="blog-details-right-sidebar.html">blog Details 2</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="contact.html">contact us</a></li>
                                  </ul>
                              </nav>*/}
                          </div>
                          <div className="basic-mobile-menu visible-xs visible-sm">
                              <nav id="mobile-nav">
                                  <ul className="basic-menu">
                                      <li>
                                          <Link to="/">Início</Link>
                                      </li>
                                      {isLoggedIn() ? (
                                          <>
                                              <MenuCarrinhoComponent />
                                              <li>
                                                  <Link to="/app/minha-conta"> <i className="fa fa-user-o"/> Minha conta</Link>
                                                  <ul>
                                                      <li><Link to="/app/minha-conta">Meus dados</Link></li>
                                                      <li><Link to="/app/meus-pedidos">Meus pedidos</Link></li>

                                                      <li><a className="clickable"
                                                             onClick={event => {
                                                                 event.preventDefault()
                                                                 logout(() => navigate(`/`))
                                                             }}>Sair</a></li>
                                                  </ul>
                                              </li>
                                          </>
                                      ) : (
                                          <>
                                              <li>
                                                  <Link to="/carrinho">
                                                      Carrinho
                                                  </Link>
                                              </li>
                                              <li>
                                                  <Link to="/login">Entrar ou criar conta</Link>
                                              </li>
                                          </>
                                      )}
                                  </ul>
                                  {/*<ul className="basic-menu">
                                      <li><a href="index.html">home</a>
                                          <ul>
                                              <li><a href="index.html">Home style 1</a></li>
                                              <li><a href="index-2.html">Home style 2</a></li>
                                              <li><a href="index-3.html">Home style 3</a></li>
                                              <li><a href="index-4.html">Home style 4</a></li>
                                              <li><a href="index-5.html">Home style 5</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="about.html">about us</a></li>
                                      <li><a href="doctor.html">Doctors</a>
                                          <ul>
                                              <li><a href="doctor.html">doctor style 1</a></li>
                                              <li><a href="doctor-2.html">doctor style 2</a></li>
                                              <li><a href="doctor-single.html">doctor Details</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="#">pages</a>
                                          <ul>
                                              <li><a href="service.html">service</a></li>
                                              <li><a href="service-details.html">service-details</a></li>
                                              <li><a href="departments-style-1.html">departments style 1</a></li>
                                              <li><a href="departments-style-2.html">departments style 2</a></li>
                                              <li><a href="department-single.html">department single</a></li>
                                              <li><a href="appointment-advanced.html">appointment</a></li>
                                              <li><a href="book-appointment-form.html">appointment form</a></li>
                                              <li><a href="gallery-2.html">gallery 3 column</a></li>
                                              <li><a href="gallery.html">gallery 2 column</a></li>
                                              <li><a href="faq.html">faq</a></li>
                                              <li><a href="features.html">features</a></li>
                                              <li><a href="policies.html">policies</a></li>
                                              <li><a href="pricing.html">pricing</a></li>
                                              <li><a href="process.html">process</a></li>
                                              <li><a href="testimonial.html">testimonial</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="product.html">shop</a>
                                          <ul>
                                              <li><a href="product.html">product</a></li>
                                              <li><a href="product-left-sidebar.html">product left sidebar</a></li>
                                              <li><a href="product-right-sidebar.html">product right sidebar</a></li>
                                              <li><a href="product-details.html">product-details</a></li>
                                              <li><a href="shop-cart.html">shop cart</a></li>
                                              <li><a href="shop-checkout.html">shop checkout</a></li>
                                              <li><a href="login.html">login</a></li>
                                              <li><a href="register.html">register</a></li>
                                              <li><a href="password-recovery.html">password recovery</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="blog-left-sidebar.html">blog</a>
                                          <ul>
                                              <li><a href="blog-2-col.html">blog 2 col</a></li>
                                              <li><a href="blog-3-col.html">blog 3 col</a></li>
                                              <li><a href="blog-no-sidebar.html">blog no sidebar</a></li>
                                              <li><a href="blog-right-sidebar.html">blog right sidebar</a></li>
                                              <li><a href="blog-details.html">blog Details 1</a></li>
                                              <li><a href="blog-details-right-sidebar.html">blog Details 2</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="contact.html">contact us</a></li>
                                  </ul>*/}
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </header>

        <main>{children}</main>

        <footer>
            <div className="footer-top-area gray-bg pt-90 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mb-40">
                            <div className="footer-widget">
                                <h4><span>Contatos</span></h4>
                                {/*<p>Medifine Bibendum auctor, nisi elit consequat ipsum, nec sagittis sem</p>*/}
                                <div className="contact-widget">
                                    <ul>
                                        <li className='hide'>
                                            <i className="fa fa-home"/><p>Medifine link Ltd, Manhattan 1258, New
                                            York, USA Lahore</p>
                                        </li>
                                        <li>
                                            <i className="fa fa-globe"/><p><Link to="/">{urlSite()}</Link></p>
                                        </li>
                                        <li>
                                            <i className="fa fa-envelope"/><p><Link to="/">{emailContato}</Link></p>
                                        </li>
                                        <li>
                                            <i className="fa fa-mobile"/><p className="phone-number">{telefoneContato}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-40">
                            <div className="footer-widget">
                                <h4><span>Orthonet links</span></h4>
                                <ul className="footer-nav list-unstyled clearfix">
                                    <li><Link to="/"><i className="fa fa-long-arrow-right"/>Home</Link></li>
{/*
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Doctors</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>About US</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Departments</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Services</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Blog</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Why US</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Medifine Care</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Specilaties</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Timetable</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Events</a></li>
                                    <li><a href="#."><i className="fa fa-long-arrow-right"></i>Contact Us</a></li>
*/}
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3 mb-40">
                            <div className="footer-widget">
                                <h4><span>newsletter</span></h4>
                                <div className="newsletter clearfix">
                                    <i className="fa fa-envelope"/>
                                    <p className="newsletter-text">Acompanhe nossas atualizações no seu e-mail</p>
                                    <form action="#">
                                        <input type="text" placeholder="Seu nome"/>
                                        <input type="email" placeholder="Seu e-mail favorito"/>
                                        <button className="btn">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area theme-bg">
                <div className="container">
                    <p className="copyright text-center">Copyright &copy; 2022 Orthonet. Todos os direitos reservados.</p>
                </div>
            </div>

            <div id='alertaCookieDiv' className={(checkAceiteCookie()?'hide':'')+' container'} style={{position:'fixed',height:'auto',minHeight:'100px',maxHeight:'170px',bottom:'0',width:'100%',backgroundColor:'rgba(1,1,1,0.2)'}}>
                <div className="container h-100">
                    <div className="panel panel-default h-100">
                        <div className="panel-body row">
                            <div className='col-9'>
                                <p className='ml-10'>
                                    Este site usa cookies para garantir que você obtenha a melhor experiência.
                                    Para mais detalhes veja nossa <a href="#">política de privacidade </a> e os <a href="#">termos de uso</a>
                                </p>
                            </div>
                            <div className='text-center'>
                                <button className='btn btn-sm btn-success' onClick={criarCookieAceite}>aceitar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
