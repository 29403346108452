

exports.telefoneContato = '(91) 9988244142'
exports.emailContato = 'suporte.orthonet@gmail.com'

exports.urlSite = ()=>{
    return process.env.NODE_ENV==='development'?'http://localhost:3000':'https://www.orthonetshop.com.br'
}

exports.getMPPubKey = ()=>{
    let DEV_KEY = 'TEST-5edb5e93-452f-4405-837d-f035177ccb36';
    let PROD_KEY = '';
    return process.env.NODE_ENV==='development'? DEV_KEY: PROD_KEY
}
