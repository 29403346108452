import * as React from "react"
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {ProdutoService} from "../../services/produto.service";
import {numberToReal} from "../../services/utils.service";
import {ShimmerSectionHeader, ShimmerThumbnail} from "react-shimmer-effects";
import {Image} from "react-img-placeholder";
import CarrinhoService from "../../services/carrinho.service";
import PesquisarInputUiComponent from "../../components/ui/pesquisarInput.ui.component";


class App extends React.Component {

    state={
        loading:true,
        produtoPageDetails:null,
        produto:{},
        produtoEstoqueList:[],
        slug:null,
        galeriaIndexSelected:0,
        galeria:{},

        coresDisponiveisList:[],
        tamanhosDisponiveisList:[],
        membrosDisponiveisList:[],

        tamanhosSelectList:[],
        membrosSelectList:[],

        corSelecionada:{},
        tamanhoSelecionado:null,
        membroSelecionado:null,

        produtoEstoqueSelecionado:{
            quantidade:0
        },
        quantidadeSelecionada:0
    }
    constructor(props) {
        super(props);
        this.loadProdutoDetailsBySlug = this.loadProdutoDetailsBySlug.bind(this)
        this.findProdutoEstoquePorOpcoesSelecionadas = this.findProdutoEstoquePorOpcoesSelecionadas.bind(this)
        this.adicionarAoCarrinho = this.adicionarAoCarrinho.bind(this)
    }

    componentDidMount() {

        let slug = this.props.location.pathname.replace('/produto/','');
        this.setState({slug});
        setTimeout(()=>this.loadProdutoDetailsBySlug(),100)


    }

    adicionarAoCarrinho(){

        let ref = this
        if(this.state.produtoEstoqueSelecionado){
            CarrinhoService.adicionarItemCarrinho(this.state.produtoEstoqueSelecionado, 1).then(() => {
                window.toastr.success('Item adicionado com sucesso', '', {closeButton: true})
                ref.setState({refresh: true})
            })
        }

    }

    loadProdutoDetailsBySlug(){

        this.setState({loading:true})
        let stateRef = this.state;
        let prosRef = this.props;
        ProdutoService.getProdutoPageDetailsBySlug(stateRef.slug).then((produtoPageDetails)=>{

            stateRef.produto = produtoPageDetails.produto
            stateRef.produtoEstoqueList = produtoPageDetails.produtoEstoqueList
            stateRef.galeria = produtoPageDetails.galeria

            stateRef.coresDisponiveisList = produtoPageDetails.coresDisponiveis
            stateRef.tamanhosDisponiveisList = produtoPageDetails.tamanhosDisponiveis
            stateRef.membrosDisponiveisList = produtoPageDetails.membrosDisponiveis


            let produtoEstoqueParameter = prosRef.location.state.produtoEstoque;
            if (produtoEstoqueParameter) {
                stateRef.produtoEstoqueSelecionado = stateRef.produtoEstoqueList.find((produtoEstoque)=> produtoEstoque.id===produtoEstoqueParameter.id );
                stateRef.corSelecionada = stateRef.produtoEstoqueSelecionado.ProdutoCor;
                stateRef.tamanhoSelecionado = stateRef.produtoEstoqueSelecionado.tamanho;
                stateRef.membroSelecionado = stateRef.produtoEstoqueSelecionado.membro;
                if(stateRef.produtoEstoqueSelecionado.quantidade>0){
                    stateRef.quantidadeSelecionada=1
                }

            }else{
                if(stateRef.coresDisponiveisList.length>0){
                    stateRef.corSelecionada = stateRef.coresDisponiveisList[0]
                }
                if(stateRef.tamanhosDisponiveisList.length>0){
                    stateRef.tamanhoSelecionado = stateRef.tamanhosDisponiveisList[0]
                }
                if(stateRef.membrosDisponiveisList.length>0){
                    stateRef.membroSelecionado = stateRef.membrosDisponiveisList[0]
                }
            }



            this.findProdutoEstoquePorOpcoesSelecionadas()

        }).catch(err=> {}).finally(()=>{
            this.setState({loading:false})
        })
    }

    findProdutoEstoquePorOpcoesSelecionadas(){

        let state1 = this.state;
        let find = state1.produtoEstoqueList.find((produtoEstoque)=>{
            return produtoEstoque.ProdutoCor.id===state1.corSelecionada.id &&
                    produtoEstoque.tamanho === state1.tamanhoSelecionado &&
                produtoEstoque.membro === state1.membroSelecionado;
        });

        if(find){
            state1.produtoEstoqueSelecionado = {...find}
            state1.quantidadeSelecionada=1;
        }else{
            state1.quantidadeSelecionada=0;
            state1.produtoEstoqueSelecionado = {quantidade: 0};
        }
        this.setState({refresh:true})
    }


    render(){
        return (
            <Layout>
                <Seo title={this.state.produto.nome || ''} />
                    {/*<div className="hide basic-breadcrumb-area bg-opacity bg-1 ptb-100">
                        <div className="container">
                            <div className="basic-breadcrumb text-center">
                                <h3 className="">Product Details</h3>
                                <ol className="breadcrumb text-xs">
                                    <li><a href="index.html">Home</a></li>
                                    <li><a href="#">Product</a></li>
                                    <li className="active">Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
*/}

                    <div className="product-area divider-bottom pb-30 ">

                        <div className="container">
                            <PesquisarInputUiComponent />
                            <div className="row pt-30">

                                <div className="col-sm-6 col-xs-12">
                                    <div className="large-product">
                                        <ul className="large-product-tab" role="tablist">

                                            {this.state.loading && (
                                                <ShimmerThumbnail width={448} height={540} rounded/>
                                            )}
                                            {
                                                this.state.loading===false && this.state.galeria.ItemMidia && this.state.galeria.ItemMidia.length > 0 &&
                                                this.state.galeria.ItemMidia.map((itemMidia,idx) => (
                                                    < >
                                                        <li key={idx} role="presentation" className={this.state.galeriaIndexSelected===idx?'active':''}>
                                                            <a className="clickable" aria-controls="home" role="tab" data-toggle="tab" onClick={()=>this.setState({galeriaIndexSelected:idx})}>
                                                                <img src={itemMidia.lowUrl} alt=""/>
                                                            </a>
                                                        </li>
                                                    </>
                                                ))
                                            }


                                        </ul>
                                        {this.state.loading && (
                                            <ShimmerThumbnail width={448} height={540} rounded/>
                                        )}


                                        {
                                            this.state.galeria.ItemMidia && this.state.galeriaIndexSelected > -1 && (
                                                <div className="tab-content large-img-content">
                                                    <div role="tabpanel" className="tab-pane active" id="home">
                                                        {/*<img src={this.state.galeria.ItemMidia[this.state.galeriaIndexSelected].mediumUrl} alt=""/>*/}
                                                        <Image
                                                            src={this.state.galeria.ItemMidia[this.state.galeriaIndexSelected].mediumUrl}
                                                            alt={this.state.produto.nome}
                                                            width={450}
                                                            height={539}
                                                            placeholderColor="white"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>


                                <div className="col-sm-6 col-xs-12">
                                    <div className="product-description mb-30">
                                        {this.state.loading && (<ShimmerSectionHeader/>)}
                                        <h2 className="m-b-5">{this.state.produto.nome}</h2>
                                        {/*<span className="star-rating star-rating-4"></span>
                                        <a href="#" className="text-xs">2 customer reviews</a>*/}
                                    </div>
                                    <div className="product-price desc-price  mb-30">
                                        {this.state.loading===false && <span>R$ {numberToReal(this.state.produto.preco)}</span>}
                                        {this.state.produto.precoAnterior>0 && <span className="price-old">R$ {numberToReal(this.state.produto.precoAnterior)}</span> }
                                    </div>
                                    <hr/>
                                    <div dangerouslySetInnerHTML={{ __html:this.state.produto.descricaoSimples}}></div>
                                    <div className='text-center mt-20'>
                                        <button class='btn btn-link btn-sm' onClick={()=>{window.document.querySelector('#maisDetalhesDiv').scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'})}} >
                                            <i className='fa fa-plus'/> mais detalhes
                                        </button>
                                    </div>
                                    <hr/>
                                    <form >
                                        {
                                            this.state.coresDisponiveisList && this.state.coresDisponiveisList.length>0 && (
                                                <div className="row">
                                                    <div className="col-sm-6" style={{backgroundColor:'aliceblue'}}>
                                                        <div className="form-group">
                                                            <label className="">Cor: </label>

                                                            {
                                                                this.state.coresDisponiveisList.map((ProdutoCor,idx)=>(
                                                                        <div  style={{border:'1px solid #000000;'}} key={idx} onClick={()=>{
                                                                            this.state.corSelecionada = ProdutoCor
                                                                            this.findProdutoEstoquePorOpcoesSelecionadas()
                                                                        } } className={ (this.state.corSelecionada.id===ProdutoCor.id?'color-selected':'border-1px-black')+ ' badge user-select-none m-15 clickable '} style={{backgroundColor:ProdutoCor.hexColor}}  title={ProdutoCor.nome}>	&nbsp;	&nbsp; </div>
                                                                    )
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        { this.state.membrosDisponiveisList && this.state.membrosDisponiveisList.length>0 && (
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="">Membro</label>
                                                        <select className="form-control" value={this.state.membroSelecionado} onChange={(event)=>{
                                                            this.state.membroSelecionado = event.target.value
                                                            this.findProdutoEstoquePorOpcoesSelecionadas()
                                                        } }>
                                                            {
                                                                this.state.membrosDisponiveisList.map((tamanho,idx)=>(
                                                                    <option value={tamanho} key={idx} >{tamanho}</option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                        }
                                        { this.state.tamanhosDisponiveisList && this.state.tamanhosDisponiveisList.length>0 && (
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="">Tamanho</label>
                                                    <select className="form-control" value={this.state.tamanhoSelecionado} onChange={(event)=>{
                                                        this.state.tamanhoSelecionado = event.target.value
                                                        this.findProdutoEstoquePorOpcoesSelecionadas()
                                                    } }>
                                                        {
                                                            this.state.tamanhosDisponiveisList.map((tamanho,idx)=>(
                                                                <option value={tamanho} key={idx} >{tamanho}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                            )
                                        }

                                        <div className="row">

                                            <div className="col-sm-6">
                                                <div className={(this.state.produtoEstoqueSelecionado.quantidade < this.state.quantidadeSelecionada?'has-error':'')+' form-group'}>
                                                    <label className="">Quantidade disponível: {this.state.produtoEstoqueSelecionado.quantidade}</label>
                                                    <input disabled={this.state.produtoEstoqueSelecionado.quantidade===0} type="number" className="form-control" step="1" min="1" value={this.state.quantidadeSelecionada} max={this.state.produtoEstoqueSelecionado.quantidade || 0} onChange={(event)=>this.setState({quantidadeSelecionada:parseInt(event.target.value)})}  />
                                                    <span className={(this.state.produtoEstoqueSelecionado.quantidade < this.state.quantidadeSelecionada?'':'hide')+' text-danger'}>
                                                        Quantidade não disponível
                                                    </span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-6">
                                                <button type='button' onClick={()=>this.adicionarAoCarrinho()} disabled={this.state.loading || this.state.quantidadeSelecionada===0 || this.state.produtoEstoqueSelecionado.quantidade===0 || this.state.produtoEstoqueSelecionado.quantidade < this.state.quantidadeSelecionada } className="btn btn-block btn-round btn-base">Adicionar ao carrinho</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row mt-70" id='maisDetalhesDiv'>
                                <div className="col-sm-12">

                                    <ul className="nav-text-tabs">
                                        <li className="active">
                                            <a className='clickable' href="#descricaoDiv"  data-toggle="tab">Descrição</a></li>
                                        <li>
                                            <a className='clickable' href="#descricaoTecnicaDiv" data-toggle="tab">Descrição técnica</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" >

                                        <div id="descricaoDiv" className="tab-pane active" dangerouslySetInnerHTML={{ __html:this.state.produto.descricao}}>
                                        </div>

                                        <div id="descricaoTecnicaDiv" className="tab-pane" dangerouslySetInnerHTML={{ __html:this.state.produto.descricaoTecnica}}>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hide related-product-area pt-90 pb-60">
                        <div className="container">
                            <div className="area-title text-center">
                                <h2>Related Products</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi tempora veritatis nemo aut ea
                                    iusto eos est expedita, quas ab adipisci.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-4 mb-30">
                                    <div className="product-box">
                                        <div className="product-img">
                                            <a href="product-details.html">
                                                {/*<img src="img/product/5.jpg" alt=""/>*/}
                                            </a>
                                            <div className="sale-tag">
                                                <span>sale</span>
                                            </div>
                                            <div className="action-box">
                                                <a href="#"><i className="ion-bag"></i></a>
                                                <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                <a href="#"><i className="ion-heart"></i></a>
                                            </div>
                                        </div>
                                        <div className="product-content text-center">
                                            <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                            <div className="product-price">
                                                <span>$39</span>
                                                <span className="price-old">$50</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 mb-30">
                                    <div className="product-box">
                                        <div className="product-img">
                                            <a href="product-details.html">
                                                {/*<img src="img/product/5.jpg" alt=""/>*/}
                                            </a>
                                            <div className="action-box">
                                                <a href="#"><i className="ion-bag"></i></a>
                                                <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                <a href="#"><i className="ion-heart"></i></a>
                                            </div>
                                        </div>
                                        <div className="product-content text-center">
                                            <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                            <div className="product-price">
                                                <span>$50</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 mb-30">
                                    <div className="product-box">
                                        <div className="product-img">
                                            <a href="product-details.html">
                                                {/*<img src="img/product/5.jpg" alt=""/>*/}
                                            </a>
                                            <div className="action-box">
                                                <a href="#"><i className="ion-bag"></i></a>
                                                <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                <a href="#"><i className="ion-heart"></i></a>
                                            </div>
                                        </div>
                                        <div className="product-content text-center">
                                            <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                            <div className="product-price">
                                                <span>$39</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 mb-30">
                                    <div className="product-box">
                                        <div className="product-img">
                                            <a href="product-details.html">
                                                {/*<img src="img/product/5.jpg" alt=""/>*/}
                                            </a>
                                            <div className="sale-tag">
                                                <span className="new">new</span>
                                            </div>
                                            <div className="action-box">
                                                <a href="#"><i className="ion-bag"></i></a>
                                                <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                <a href="#"><i className="ion-heart"></i></a>
                                            </div>
                                        </div>
                                        <div className="product-content text-center">
                                            <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                            <div className="product-price">
                                                <span>$55</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default App
