import React from "react";
import {Link, navigate} from "gatsby";
import {logout} from "../services/auth";
import App from "../pages/produto/[produto-slug]";
import CarrinhoService from "../services/carrinho.service";

class MenuCarrinhoComponent extends React.Component {

    state = {
        quantidadeItensCarrinho:0
    }
    constructor(props) {
        super(props);
        this.loadCarrinho = this.loadCarrinho.bind(this)
    }

    componentDidMount() {

        this.loadCarrinho()

    }

    loadCarrinho(){
        CarrinhoService.carregarCarrinho().then((carrinho)=>{
            this.setState({quantidadeItensCarrinho:carrinho.ItemCarrinhos?carrinho.ItemCarrinhos.length:0})
        })
    }

    render() {
        return (
            <li>
                <Link to="/carrinho">
                    Carrinho {this.state.quantidadeItensCarrinho>0 && (<><i className="fa fa-shopping-cart"/>({this.state.quantidadeItensCarrinho})</>)}
                </Link>
                {/*<ul>
                    <li><Link to="/app/minha-conta">Meus dados</Link></li>
                    <li><Link to="/app/meus-pedidos">Meus pedidos</Link></li>

                </ul>*/}
            </li>
        );
    }

}

export default MenuCarrinhoComponent
