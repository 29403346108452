import {getEndpointUrl} from "./utils.service";

export const ProdutoService = {

    async getProdutoPageDetailsBySlug(slug){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+'/api/produto/page-details/'+slug, requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });
    },
    async searchProdutoEstoqueByTermo(termo,categoriasFilterList=[],limit=100,offset=0){
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({termo,limit,offset,categoriasFilterList})
        };

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+'/api/produto/pesquisar-produto-estoque', requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });
    },
}


