import {getJWTToken, isLoggedIn} from "./auth";
import {getEndpointUrl} from "./utils.service";

const CarrinhoService = {

    carrinho:{
        valorTotal:0.0,
        frete:0.0,
        ItemCarrinhos:[ ]
    },
    listeners:[],

    async adicionarItemCarrinho(produtoEstoque,quantidade){
        if(isLoggedIn()){
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'JWT '+getJWTToken()
                },
                body: JSON.stringify({produtoEstoqueId:produtoEstoque.id,quantidade:quantidade})
            };

            return new Promise((resolve, reject) => {
                let endpointUrl = getEndpointUrl();
                fetch(endpointUrl+'/api/carrinho/add-item-carrinho', requestOptions)
                    .then(async (response) => {
                        if(response.ok){
                            CarrinhoService.carrinho = response.json()
                            await CarrinhoService.carregarCarrinho()
                            return CarrinhoService.carrinho
                        }else{
                            reject(response)
                        }

                    })
                    .then(data => {
                        resolve(data)
                    } )
                    .catch(err=> reject(err));
            });
        }else{

            CarrinhoService.carrinho.ItemCarrinhos.push({id:new Date().getTime(),quantidade:quantidade,ProdutoEstoque:produtoEstoque,valor:quantidade*produtoEstoque.Produto.preco})
            CarrinhoService.carrinho.valorTotal=0;

            CarrinhoService.carrinho.ItemCarrinhos.forEach(item=>{
                console.log(item.valor)
                CarrinhoService.carrinho.valorTotal += item.valor
            })
            console.log(CarrinhoService.carrinho);

        }
    },
    async removerItemCarrinho(itemCarrinhoId){
        if(isLoggedIn()){
            const requestOptions = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'JWT '+getJWTToken()
                },
            };

            return new Promise((resolve, reject) => {
                let endpointUrl = getEndpointUrl();
                fetch(endpointUrl+'/api/carrinho/remove-item-carrinho/'+itemCarrinhoId, requestOptions)
                    .then(async (response) => {
                        if(response.ok){
                            await CarrinhoService.carregarCarrinho()
                            resolve(CarrinhoService.carrinho)
                        }else{
                            reject(response)
                        }

                    })
                    .catch(err=> reject(err));
            });
        }else{

            for(let idx=0;idx<CarrinhoService.carrinho.ItemCarrinhos.length;idx++){
                console.log(CarrinhoService.carrinho.ItemCarrinhos[idx].id,itemCarrinhoId)
                if(CarrinhoService.carrinho.ItemCarrinhos[idx].id===itemCarrinhoId){
                    CarrinhoService.carrinho.ItemCarrinhos.splice(idx,1)
                    break;
                }
            }

            CarrinhoService.carrinho.valorTotal=0;

            CarrinhoService.carrinho.ItemCarrinhos.forEach(item=>{
                CarrinhoService.carrinho.valorTotal += item.valor
            })
            return (CarrinhoService.carrinho)

        }
    },
    async atualizarItemCarrinho(carrinhoId,itemCarrinhoId,quantidade){
        if(isLoggedIn()){
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'JWT '+getJWTToken()
                },
                body: JSON.stringify({itemCarrinhoId,quantidade,carrinhoId})
            };

            return new Promise((resolve, reject) => {
                let endpointUrl = getEndpointUrl();
                fetch(endpointUrl+'/api/carrinho/atualizar-item-carrinho', requestOptions)
                    .then(async (response) => {
                        if(response.ok){
                            await CarrinhoService.carregarCarrinho()
                            resolve(CarrinhoService.carrinho)
                        }else{
                            reject(response)
                        }

                    })
                    .catch(err=> reject(err));
            });
        }else{

        }
    },
    async carregarCarrinho(){
        if(isLoggedIn()){
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':'JWT '+getJWTToken()
                },
            };

            return new Promise((resolve, reject) => {
                let endpointUrl = getEndpointUrl();
                fetch(endpointUrl+'/api/cliente/carrinho', requestOptions)
                    .then(async (response) => {
                        if(response.ok){
                            CarrinhoService.carrinho = await response.json()
                            return CarrinhoService.carrinho
                        }else{
                            reject(response)
                        }

                    })
                    .then(data => {
                        resolve(data)
                    } )
                    .catch(err=> reject(err));
            });
        }else{
            return CarrinhoService.carrinho
        }

    },
    async fecharPedido(){

    }


}

export default CarrinhoService;
