import React from "react";
import {Link} from "gatsby";
import {Image} from "react-img-placeholder";
import {numberToReal} from "../../services/utils.service";
import CarrinhoService from "../../services/carrinho.service";

class ProdutoEstoqueUiComponent extends React.Component {

    state={
        produtoEstoque:null
    }
    constructor(props) {
        super(props);
        this.state.produtoEstoque = props.produtoEstoque

        this.addItemCarrinho = this.addItemCarrinho.bind(this)
    }

    componentDidMount() {

    }

    addItemCarrinho(produtoEstoque){

        let ref =this
        CarrinhoService.adicionarItemCarrinho(produtoEstoque,1).then(()=>{
            window.toastr.success('Item adicionado com sucesso','',{closeButton : true})
            ref.setState({refresh:true})
        })

    }

    render() {
        return (
            <div className="product-box">
                <div className="product-img">
                    <Link to={'/produto/'+this.state.produtoEstoque.Produto.slug} state={{ produtoEstoque: this.state.produtoEstoque }} >
                        {/*<img src={produtoEstoque.imagemPrincipal.mediumUrl} alt=""/>*/}
                        <Image
                            src={this.state.produtoEstoque.imagemPrincipal.mediumUrl}
                            alt={this.state.produtoEstoque.Produto.nome}
                            width={262}
                            height={300}
                            placeholderColor="white"
                        />
                    </Link>
                    <div className="action-box">
                        { this.state.produtoEstoque.quantidade>0 && (
                            <a className='clickable' onClick={()=>this.addItemCarrinho(this.state.produtoEstoque)}><i className="ion-bag"/></a>
                        )}
                        {/*<a href="#"><i className="ion-ios-search-strong"></i></a>*/}
                        {/*<a href="#"><i className="ion-heart"></i></a>*/}
                    </div>
                </div>
                <div className="product-content text-center">
                    <h3 className="product-title"><a >{this.state.produtoEstoque.Produto.nome} {this.state.produtoEstoque.tamanho?'('+this.state.produtoEstoque.tamanho+')':'' }
                        {this.state.produtoEstoque.membro?'('+this.state.produtoEstoque.membro+')':'' }</a></h3>
                    <div className="product-price">
                        { this.state.produtoEstoque.quantidade===0 && (
                            <span className='text-danger'>Não disponível</span>
                        )}
                        {
                            this.state.produtoEstoque.quantidade>0 && (
                                <>
                                    <span>{'R$ '+numberToReal(this.state.produtoEstoque.Produto.preco)}</span>
                                    { this.state.produtoEstoque.Produto.precoAnterior>0 && <span className="price-old">{'R$ '+numberToReal(this.state.produtoEstoque.Produto.precoAnterior)}</span>}
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        );
    }

}

export default ProdutoEstoqueUiComponent
