import React from "react";
import {navigate} from "gatsby";
import ProdutoEstoqueUiComponent from "./produtoEstoque.ui.component";

class PesquisarInputUiComponent extends React.Component {

    state={
        loading:false,
        pequisarTermo:''
    }
    constructor(props) {
        super(props);
        this.realizarPesquisa = this.realizarPesquisa.bind(this)
        this.submitSearch = this.submitSearch.bind(this)
    }

    realizarPesquisa(){
        if(this.state.pequisarTermo){
            let pequisarTermo = encodeURI(this.state.pequisarTermo) ;
            navigate('/pesquisar?q='+ pequisarTermo)
        }
    }

    submitSearch($evt){
        if($evt.keyCode === 13 && this.state.pequisarTermo){
            this.realizarPesquisa()
        }
    }

    render() {
        return (
            <div className="input-group">
                <input type="text" className="form-control" placeholder="Pesquisar..." value={this.state.pequisarTermo} onKeyDown={(event)=>this.submitSearch(event)} onChange={(event)=> this.setState({pequisarTermo:event.target.value}) }/>
                <span className="input-group-btn">
                                        <button className="btn btn-default" disabled={!!!this.state.pequisarTermo} type="button" onClick={()=> this.realizarPesquisa()}> <i className='fa fa-search'/>   </button>
                                      </span>
            </div>
        )
    }
}

export default PesquisarInputUiComponent
