export const isBrowser = () => typeof window !== "undefined"
const getEndpointUrl = require('./utils.service').getEndpointUrl

const ORTHONET_USER = "orthonetUser";
const ORTHONET_CLIENTE = "orthonetCliente";
const JWT_TOKEN = "jwttoken";


export const getUser = () =>
    isBrowser() && window.localStorage.getItem(ORTHONET_USER)
        ? JSON.parse(window.localStorage.getItem(ORTHONET_USER))
        : {}

export const getCliente = () =>
    isBrowser() && window.localStorage.getItem(ORTHONET_CLIENTE)
        ? JSON.parse(window.localStorage.getItem(ORTHONET_CLIENTE))
        : {}

const setUser = user =>
    window.localStorage.setItem(ORTHONET_USER, JSON.stringify(user))

export const setCliente = user =>
    window.localStorage.setItem(ORTHONET_CLIENTE, JSON.stringify(user))

export const setJWTToken = token =>
    window.localStorage.setItem(JWT_TOKEN, token)


export const getJWTToken = () =>
    window.localStorage.getItem(JWT_TOKEN)

export const handleLogin = async (userAccess) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userAccess)
    };

    return new Promise((resolve, reject) => {
        let endpointUrl = getEndpointUrl();
        fetch(endpointUrl+'/api/authenticate', requestOptions)
            .then(response => {
                if(response.ok){
                    return response.json()
                }else{
                    reject(response)
                }

            })
            .then(data => {

                if(data.user){
                    setUser(data.user)
                    setCliente(data.cliente)
                }
                if(data.token){
                    setJWTToken(data.token)
                }

                resolve(data)
            } )
            .catch(err=> reject(err));


    });
}

export const register = async (formData) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return new Promise((resolve, reject) => {
        let endpointUrl = getEndpointUrl();
        fetch(endpointUrl+'/api/cliente/create', requestOptions)
            .then(response => {
                if(response.ok){
                    return response.json()
                }else{
                    reject(response)
                }

            })
            .then(data => {

                if(data.user){
                    setUser(data.user)
                    setCliente(data.cliente)
                }
                if(data.token){
                    setJWTToken(data.token)
                }

                resolve(data)
            } )
            .catch(err=> reject(err));


    });
}

export const enviarCodigoRecuperacaoEmail = async (email) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email})
    };

    return new Promise((resolve, reject) => {
        let endpointUrl = getEndpointUrl();
        fetch(endpointUrl+'/api/cliente/enviar-codigo-recuperacao-email', requestOptions)
            .then(response => {
                if(response.ok){
                    resolve()
                }else{
                    reject(response)
                }

            })
            .catch(err=> reject(err));


    });
}

export const enviarCodigoRecuperacaoENovaSenha = async (codigoRecuperacao,newPassword) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({codigoRecuperacao,newPassword})
    };

    return new Promise((resolve, reject) => {
        let endpointUrl = getEndpointUrl();
        fetch(endpointUrl+'/api/cliente/inserir-nova-senha-codigo-recuperacao', requestOptions)
            .then(response => {
                if(response.ok){
                    resolve()
                }else{
                    reject(response)
                }

            })
            .catch(err=> reject(err));


    });
}


export const isLoggedIn = () => {
    const user = getUser()

    return !!user.id
}

export const logout = callback => {
    setUser({})
    callback()
}


export const updateUserPassword = async (currentPassword, newPassword) => {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getJWTToken()},
        body: JSON.stringify({currentPassword: currentPassword,newPassword})
    };

    return new Promise((resolve, reject) => {
        let endpointUrl = getEndpointUrl();
        fetch(endpointUrl+'/api/user/update-password', requestOptions)
            .then(response => {
                if(response.ok){
                    resolve()
                }else{
                    reject(response)
                }

            })
            .then(data => {

                resolve(data)
            } )
            .catch(err=> reject(err));


    });
}
